import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "theme-expansion",
      "style": {
        "position": "relative"
      }
    }}>{`Theme expansion`}</h3>
    <p>{`New draft themes for Nieuwsblad, Belfast Telegraph, Independent and Sunday World
are added to chameleon.`}</p>
    <h3 {...{
      "id": "gva-secondary-color-becomes-blueish",
      "style": {
        "position": "relative"
      }
    }}>{`GVA Secondary color becomes blueish`}</h3>
    <p>{`Gva changes it's secondary color to be blueish instead of the previous orange
color.`}</p>
    <h3 {...{
      "id": "line-height-consistency",
      "style": {
        "position": "relative"
      }
    }}>{`Line-height consistency`}</h3>
    <p>{`All Line-heights are now consistent within a platform.`}</p>
    <h3 {...{
      "id": "nb-theme-updates",
      "style": {
        "position": "relative"
      }
    }}>{`NB theme updates`}</h3>
    <p>{`Updated existing NB theme to match better the productions implementation. So it
can be used in fragments. This includes line-heights, font-sizes and font-weight
changes. The theme now uses v1.0.1 as cdn.`}</p>
    <h3 {...{
      "id": "full-changelog",
      "style": {
        "position": "relative"
      }
    }}>{`Full changelog`}</h3>
    <p>{`Check out the full changelog for more information.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v1.6.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      